// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-en-js": () => import("./../src/pages/about/index.en.js" /* webpackChunkName: "component---src-pages-about-index-en-js" */),
  "component---src-pages-about-index-js": () => import("./../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-catalog-index-en-js": () => import("./../src/pages/catalog/index.en.js" /* webpackChunkName: "component---src-pages-catalog-index-en-js" */),
  "component---src-pages-catalog-index-js": () => import("./../src/pages/catalog/index.js" /* webpackChunkName: "component---src-pages-catalog-index-js" */),
  "component---src-pages-contact-index-en-js": () => import("./../src/pages/contact/index.en.js" /* webpackChunkName: "component---src-pages-contact-index-en-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-en-js": () => import("./../src/pages/news/index.en.js" /* webpackChunkName: "component---src-pages-news-index-en-js" */),
  "component---src-pages-news-index-js": () => import("./../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-products-index-en-js": () => import("./../src/pages/products/index.en.js" /* webpackChunkName: "component---src-pages-products-index-en-js" */),
  "component---src-pages-products-index-js": () => import("./../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-search-result-index-js": () => import("./../src/pages/search-result/index.js" /* webpackChunkName: "component---src-pages-search-result-index-js" */),
  "component---src-pages-tech-info-air-con-index-en-js": () => import("./../src/pages/tech-info/air-con/index.en.js" /* webpackChunkName: "component---src-pages-tech-info-air-con-index-en-js" */),
  "component---src-pages-tech-info-air-con-index-js": () => import("./../src/pages/tech-info/air-con/index.js" /* webpackChunkName: "component---src-pages-tech-info-air-con-index-js" */),
  "component---src-pages-tech-info-index-en-js": () => import("./../src/pages/tech-info/index.en.js" /* webpackChunkName: "component---src-pages-tech-info-index-en-js" */),
  "component---src-pages-tech-info-index-js": () => import("./../src/pages/tech-info/index.js" /* webpackChunkName: "component---src-pages-tech-info-index-js" */),
  "component---src-pages-tech-info-tech-section-index-js": () => import("./../src/pages/tech-info/tech-section/index.js" /* webpackChunkName: "component---src-pages-tech-info-tech-section-index-js" */),
  "component---src-pages-tech-info-tech-section-posts-index-js": () => import("./../src/pages/tech-info/tech-section/posts/index.js" /* webpackChunkName: "component---src-pages-tech-info-tech-section-posts-index-js" */),
  "component---src-pages-tech-info-tech-section-videos-index-js": () => import("./../src/pages/tech-info/tech-section/videos/index.js" /* webpackChunkName: "component---src-pages-tech-info-tech-section-videos-index-js" */),
  "component---src-templates-category-js": () => import("./../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-news-js": () => import("./../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-tag-js": () => import("./../src/templates/news-tag.js" /* webpackChunkName: "component---src-templates-news-tag-js" */),
  "component---src-templates-product-intro-js": () => import("./../src/templates/product-intro.js" /* webpackChunkName: "component---src-templates-product-intro-js" */),
  "component---src-templates-product-js": () => import("./../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

